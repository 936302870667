import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ContactForm from "../../components/contactForm"
import Hero from "../../components/hero"
import Services from "../../components/services2"
import Content from "../../components/content"

/**
 * GraphQL Query to retrieve page data
 *
 * @return {Object}
 */
export const ServicesPageQuery = graphql`
  {
    site {
      siteMetadata {
        email
        phone
      }
    }
    hero: file(relativePath: { eq: "pages/about/header-home-erick.jpg" }) {
      sharp: childImageSharp {
        fluid(
          quality: 100
          maxWidth: 1920
          duotone: { highlight: "#15181f", shadow: "#15181f", opacity: 55 }
        ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

/**
 * Services Page Component
 * This component is used to generate the Services page
 *
 * @param {Object} props
 */
const CompraVentaPage = ({ data }) => {
  return (
    <Layout isSecondary={true}>
      <SEO title="Compra/Venta de propiedades" description="" />

      <Hero
        type="simple"
        tagline="Estos son los principales servicios jurídicos que le ofrecemos"
        title="Compra / Venta de Propiedades"
        background={data.hero.sharp.fluid}
      />

      <Content
        tagline=""
        title="Compraventa de propiedades en Guatemala"
      >
        <p>
        El Fundamento legal de este contrato lo encontramos en el Libro Quinto art. 1790
        del Código Civil de Guatemala.
        </p>
        <p>
        <strong>¿Tienes propiedades en Guatemala y necesitas venderlas?</strong>
        </p>
        <p>
        Estimado guatemalteco te brindamos asesoría legal cuando desees vender una
        propiedad en Guatemala, para realizar dicha venta de manera rápida y segura, Llámanos hoy. 
        </p>
        <p>
        Una de las practicas más comunes que realizan los guatemaltecos que residen en los
        Estados Unidos de América es comprar  propiedades en Guatemala, el problema es que lo hacen
        a nombre de un familiar que se encuentra en dicho país bajo la mala creencia que por el hecho
        de vivir en Estados Unidos las propiedades no pueden estar registradas a su nombre,
        la problemática se da que al pasar de los años cuando el familiar a nombre de quien se
        compró la propiedad no quiere devolverla y legalmente ellos aparecen como dueños.  
        </p>
        <p>
        <strong>¿Qué debemos hacer?</strong>
        </p>
        <p>        
        Nosotros te brindamos asesoría legal para ese tipo de situaciones y además de ello podemos
        asesorarte cuando desees comprar una propiedad en Guatemala para que dicha compra sea segura,
        rápida y sobre todo que la propiedad este bajo tu nombre. Otro de los problemas que se enfrenta
        la comunidad guatemalteca que reside en los Estados Unidos es que tienen sus propiedades
        en Guatemala, las dan en arrendamiento y luego los arrendatarios se atrasan en los pagos
        de renta, no quieren pagar ni salirse de dicha propiedad. 
        </p>
        <p>
        <strong>¡¡¡Llámenos y haga una cita hoy!!!</strong> le podemos brindar asesoría para minimizar ese tipo
        de situaciones y aconsejarle como podrían tener mejores resultados y evitar arrendatarios
        morosos.
        </p>
      </Content>

      <Services />

      <ContactForm
        email={data.site.siteMetadata.email}
        phone={data.site.siteMetadata.phone}
      />
    </Layout>
  )
}

export default CompraVentaPage

